<ng-template #content><ng-content></ng-content></ng-template>

<div class="LayoutContainer">
    <div class="Layout">
        <app-toolbar id="toolbar"></app-toolbar>
        <div class="content" #content>
            <ng-container *ngTemplateOutlet="content"></ng-container>
        </div>
    </div>
</div>
