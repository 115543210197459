<div class="title" mat-dialog-title>Ingrese una organización para ver los servicios disponibles</div>
<div mat-dialog-content>
    <form [formGroup]="organization">
        <mat-form-field appearance="fill">
            <mat-label>Organizacion</mat-label>
            <input matInput type="text"  formControlName="organization"/>
            <mat-error>
                <ng-container *ngIf="organization.get('organization')">
                    Ingrese un Id De una Organización
                </ng-container>
            </mat-error>
        </mat-form-field>
    </form>
</div>
<div class="tb-row tb-justify-sb btn-mt10">
    <button class="buttonCancel" mat-raised-button mat-dialog-close (click)="noOrganization()">
        Cancelar
    </button>
    <button class="buttonAceppt" mat-raised-button (click)="enterOrganization()">
        Ver
    </button>

</div>