import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Result } from 'src/app/pages/benefits/list-benefits/list-benefits.component';

@Component({
  selector: 'app-directory-details',
  templateUrl: './directory-details.component.html',
  styleUrls: ['./directory-details.component.scss']
})
export class DirectoryDetailsComponent implements OnInit {

  public dataCard: Result = this.Data.card;

  constructor(
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public Data: any,
    public dialogRef: MatDialogRef<DirectoryDetailsComponent>,
  ) { }

  ngOnInit(): void {
  }

  public detallesDirectory(){
    localStorage.setItem('service', JSON.stringify(this.dataCard));
    this.router.navigate(['/list/benefits/' + this.dataCard.uuid]);
    this.dialogRef.close();
  }

}
