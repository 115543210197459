import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { BreakpointObserver } from '@angular/cdk/layout';
import { delay } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {

  public searchForm: FormGroup | any;
  public showMenu = false;
  public uuidOrganization: string | null = '';

  constructor(
    private form: FormBuilder,
    private breakpointObserver: BreakpointObserver,
    private router: Router
  ) { }

  ngOnInit(): void {

    this.searchForm = this.form.group({
      search: ['']
    });

    const storedUuid: string | null = localStorage.getItem('uuidOrganization');
    if(storedUuid !== null){
      //this.uuidOrganization = `?uuidOrganization=${storedUuid}`;
      this.uuidOrganization = storedUuid;
    }
  }

  ngAfterViewInit(){
    this.breakpointObserver.observe(['(max-width: 506px'])
    .pipe(delay(1))
    .subscribe((res) => {
      if(res.matches){
        this.showMenu = true;
      }else {
        this.showMenu = false;
      }
    })
  }

  public home() {
    this.router.navigate(['list/benefits'], { queryParams: { uuidOrganization: this.uuidOrganization } });
  }

}
