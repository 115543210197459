import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-enter-organization',
  templateUrl: './enter-organization.component.html',
  styleUrls: ['./enter-organization.component.scss']
})
export class EnterOrganizationComponent implements OnInit {
  public organization!: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<EnterOrganizationComponent>,
    private _formBuilder: FormBuilder,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.organization = this._formBuilder.group({
      organization: ['', Validators.required]
    });
  }

  public enterOrganization(){
    this.router.navigate(['list/benefits'], { queryParams: { uuidOrganization: this.organization.value.organization } });
    this.dialogRef.close();
  }

  public noOrganization(){
    this.router.navigate(['list/benefits']);
  }

}
